import React, { useState, useEffect } from "react";
import styles from "./styles";
import BreadCrumbs from "../BreadCrumbs";
import { Typography, Paper, useTheme, Button } from "@material-ui/core";
import SubscribedProduct from "../SubscribedProducts";
import DashboardOrangeIcon from "../../assets/dashboardicons/dashboard-icon-orange.svg";
import DashboardGreenIcon from "../../assets/dashboardicons/dashboard-icon-green.svg";
import DashboardPurpleIcon from "../../assets/dashboardicons/dashboard-icon-purple.svg";
import DashboardYellowIcon from "../../assets/dashboardicons/dashboard-icon-yellow.svg";
import GroupsOrangeIcon from "../../assets/dashboardicons/groups-icon-orange.svg";
import GroupsGreenIcon from "../../assets/dashboardicons/groups-icon-green.svg";
import GroupsPurpleIcon from "../../assets/dashboardicons/groups-icon-purple.svg";
import GroupsYellowIcon from "../../assets/dashboardicons/groups-icon-yellow.svg";
import ReportOrangeIcon from "../../assets/dashboardicons/report-icon-orange.svg";
import ReportGreenIcon from "../../assets/dashboardicons/report-icon-green.svg";
import ReportPurpleIcon from "../../assets/dashboardicons/report-icon-purple.svg";
import ReportYellowIcon from "../../assets/dashboardicons/report-icon-yellow.svg";
import InstructorOrangeIcon from "../../assets/dashboardicons/resources-icon-orange.svg";
import InstructorGreenIcon from "../../assets/dashboardicons/resources-icon-green.svg";
import InstructorPurpleIcon from "../../assets/dashboardicons/resources-icon-purple.svg";
import InstructorYellowIcon from "../../assets/dashboardicons/resources-icon-yellow.svg";
import DigitalLiteracyLogo from "../../assets/logos/products/jasperactive-DL-logo.svg";
import JMosLogo from "../../assets/logos/products/jmos-orange-logo.svg";
import JMosOnlineLogo from "../../assets/logos/products/jmos-online-orange-logo.png";
import QuickBookLogo from "../../assets/logos/products/jasperactive-QB-logo.svg";
import JACCourseLogo from "../../assets/logos/products/jasperactive-ac.svg";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { Loading } from "../ui";
import axios from "axios";

const Dashboard = () => {
  const { user, userRole, setUserRole } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const classes = new styles();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [defaultproducts, setDefaultProducts] = useState([
    {
      theme: "",
      logo: "",
      goToTitle: "",
      goTourl: "",
    },
  ]);

  useEffect(() => {
    if (user) {
      Promise.all([
        axios.get(`/Home/GetProducts/${window.location.hostname}`),
        axios.get(`/Home/GetProducstUrl/${window.location.hostname}`),
      ])
        .then((values) => {
          const dashboard = values[0].data;
          const urlInfo = values[1].data;
          const role = dashboard.role;
          let productList = [];

          if (role === "Instructor") {
            productList = getTeacherProducts(dashboard.teacherProducts);
          } else {
            productList = getStudentProducts(dashboard.studentProducts);
          }

          if (productList.length === 0) {
            setDefaultProducts(getDefaultProducts(theme, urlInfo, dashboard));
          } else {
            setProducts(productList);
          }

          setUserRole(role);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => setLoading(false));
    }
  }, [
    user,
    setUserRole,
    theme,
    theme.palette.product.adobeCreate,
    theme.palette.product.cci,
    theme.palette.product.digitalLiteracy,
    theme.palette.product.quickbooks,
  ]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className={classes.container}>
        <Paper className={classes.userInfo}>
          <BreadCrumbs />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginLeft: 24, marginBottom: 16 }}>
              <Typography className={classes.userName}>
                Hi {user?.name}!
              </Typography>
              <Typography className={classes.userType}>
                Role: {userRole}
              </Typography>
            </div>
            <Button
              className={classes.getStartedButton}
              onClick={() => {
                window.open(
                  "https://support.jasperactive.com/support/home",
                  "_blank"
                );
              }}
            >
              Getting Started Instructions
            </Button>
          </div>
        </Paper>

        {products.length === 0 ? (
          <div className={classes.productSection}>
            <Typography className={classes.subscribeProduct}>
              Have a product license/group code? Click on that product link to
              get started:
            </Typography>

            {defaultproducts.map((defaultproduct, index) => {
              return (
                <SubscribedProduct
                  key={index}
                  logo={defaultproduct.logo}
                  goToTitle={defaultproduct.goToTitle}
                  theme={defaultproduct.theme}
                  url={defaultproduct.goTourl}
                />
              );
            })}
          </div>
        ) : (
          <div className={classes.productSection}>
            <Typography className={classes.subscribeProduct}>
              Subscribed Products
            </Typography>
            {products.map((value, i) => {
              return (
                <SubscribedProduct
                  key={i}
                  name={value.name}
                  title={value.title}
                  theme={value.theme}
                  logo={value.logo}
                  goToTitle={value.goToTitle}
                  role={userRole}
                  instructorMenus={value.instructorsMenu}
                  url={value.url}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
};

const getDefaultProducts = (theme, urlInfo, userProducts) => {
  return [
    {
      theme: theme.palette.product.cci,
      logo: JMosLogo,
      goToTitle: "Go To Jasperactive Microsoft Office",
      goTourl: getDefaultUrl("Jasperactive MOS", urlInfo),
    },
    {
      theme: theme.palette.product.digitalLiteracy,
      logo: DigitalLiteracyLogo,
      goToTitle: "Go To Jasperactive Digital Literacy",
      goTourl: getDefaultUrl(
        "Jasperactive APP",
        userProducts.defaultProductsUrl
      ),
    },
    {
      theme: theme.palette.product.quickbooks,
      logo: QuickBookLogo,
      goToTitle: "Go To Jasperactive QuickBooks",
      goTourl: getDefaultUrl("Jasperactive APP", urlInfo),
    },
    {
      theme: theme.palette.product.adobeCreate,
      logo: JACCourseLogo,
      goToTitle: "Get Download Instructions",
      goTourl: getDefaultUrl("Adobe Create", urlInfo),
    },
  ];
};

const getDefaultUrl = (productName, data) => {
  const product = data.find((product) => {
    return product.applicationName === productName;
  });

  if (product) {
    return product.url;
  } else {
    return "";
  }
};

const getTeacherProducts = (teacherProducts) => {
  return teacherProducts.map((item) => {
    return {
      name: item.applicationName,
      theme: item.color,
      logo: getProductLogo(item.productName),
      goToTitle: getApplicationTitle(item),
      url: item.url,
      title:
        item.applicationName === "Jasperactive APP" ? item.productName : null,
      instructorsMenu: [
        {
          icon: getDashboardIcon(item.productName),
          title: "Home",
          url: item.dashboardUrl,
        },
        {
          icon: getManageGroupIcon(item.productName),
          title: "Manage Groups",
          url: item.manageGroupsUrl,
        },
        {
          icon: getReportIcon(item.productName),
          title: "View Reports",
          url: item.reportsUrl,
        },
        {
          icon: getInstructorIcon(item.productName),
          title: "Instructor Resources",
          url: item.resourcesUrl,
        },
      ],
    };
  });
};

const getStudentProducts = (studentProducts) => {
  return studentProducts.map((item) => {
    return {
      name: item.applicationName,
      theme: item.color,
      logo: getProductLogo(item.productName),
      goToTitle: getApplicationTitle(item),
      url: item.url,
      title:
        item.applicationName === "Jasperactive APP" ? item.productName : null,
    };
  });
};

const getProductLogo = (name) => {
  if (name.toLowerCase().includes("digital")) {
    return DigitalLiteracyLogo;
  } else if (name.toLowerCase().includes("mos")) {
    return JMosLogo;
  } else if (name.toLowerCase().includes("office")) {
    return JMosOnlineLogo;
  } else if (name.toLowerCase().includes("quick")) {
    return QuickBookLogo;
  } else if (name.toLowerCase().includes("adobe create")) {
    return JACCourseLogo;
  } else {
    return null;
  }
};

const getApplicationTitle = (product) => {
  const { productName: name } = product;

  if (name.toLowerCase().includes("digital")) {
    return "Go To Jasperactive Digital Literacy" + (name.includes("5") ? " GS5" : name.includes("6") ? " GS6" : "");
  } else if (name.toLowerCase().includes("mos")) {
    return "Go To Jasperactive Microsoft Office";
  } else if (name.toLowerCase().includes("office")) {
    return "Go To Office 365";
  } else if (name.toLowerCase().includes("quick")) {
    return "Go To Jasperactive QuickBooks";
  } else if (name.toLowerCase().includes("adobe create")) {
    return "Get Download Instructions";
  } else {
    return "Go To Jasperactive App";
  }
};

const getDashboardIcon = (name) => {
  if (name.toLowerCase().includes("digital")) {
    return DashboardGreenIcon;
  } else if (name.toLowerCase().includes("mos")) {
    return DashboardOrangeIcon;
  } else if (name.toLowerCase().includes("office")) {
    return DashboardOrangeIcon;
  } else if (name.toLowerCase().includes("quick")) {
    return DashboardPurpleIcon;
  } else if (name.toLowerCase().includes("adobe create")) {
    return DashboardYellowIcon;
  } else {
    return DashboardOrangeIcon;
  }
};

const getManageGroupIcon = (name) => {
  if (name.toLowerCase().includes("digital")) {
    return GroupsGreenIcon;
  } else if (name.toLowerCase().includes("MOS")) {
    return GroupsOrangeIcon;
  } else if (name.toLowerCase().includes("office")) {
    return GroupsOrangeIcon;
  } else if (name.toLowerCase().includes("quick")) {
    return GroupsPurpleIcon;
  } else if (name.toLowerCase().includes("adobe create")) {
    return GroupsYellowIcon;
  } else {
    return GroupsOrangeIcon;
  }
};

const getReportIcon = (name) => {
  if (name.toLowerCase().includes("digital")) {
    return ReportGreenIcon;
  } else if (name.toLowerCase().includes("mos")) {
    return ReportOrangeIcon;
  } else if (name.toLowerCase().includes("office")) {
    return ReportOrangeIcon;
  } else if (name.toLowerCase().includes("quick")) {
    return ReportPurpleIcon;
  } else if (name.toLowerCase().includes("adobe create")) {
    return ReportYellowIcon;
  } else {
    return ReportOrangeIcon;
  }
};

const getInstructorIcon = (name) => {
  if (name.toLowerCase().includes("digital")) {
    return InstructorGreenIcon;
  } else if (name.toLowerCase().includes("mos")) {
    return InstructorOrangeIcon;
  } else if (name.toLowerCase().includes("office")) {
    return InstructorOrangeIcon;
  } else if (name.toLowerCase().includes("quick")) {
    return InstructorPurpleIcon;
  } else if (name.toLowerCase().includes("adobe create")) {
    return InstructorYellowIcon;
  } else {
    return InstructorOrangeIcon;
  }
};

export default Dashboard;
