import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Appshell from "../components/layout/Appshell";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Home from "../components/Home";
import ProductDetail from "../components/Product";
import Dashboard from "../components/Dashboard";
import Pricing from "../components/Pricing";
import PrivateRoutes from "../components/PrivateRoutes";
import {
  PrivacyPolicy,
  PrivacyNotice,
  TermsOfService,
  DataProcessingAgreement,
  StudentDataPrivacy,
  StatementOfPrivacy,
  StudentPrivacyPledge,
  EndUserLicenseAgreement
} from "../components/PrivacyPolicy";
import {
  AuthCallback,
  Logout,
  SilentRenew,
} from "../components/authentication";
import Download from "../components/Download";
import JDL from "../components/Product/JDL";


const Routes = () => {
  return (
    <Appshell
      header={<Header />}
      footer={<Footer />}
      privacy={<PrivacyPolicy />}
    >
      <Switch>
        <Route exact={true} path="/" component={Home}></Route>
        <PrivateRoutes path="/dashboard" component={Dashboard} exact />
        <Route path="/pricing" component={Pricing}></Route>
        <Route path="/auth-callback" component={AuthCallback}></Route>
        <Route path="/logout" component={Logout}></Route>
        <Route path="/silent-renew" component={SilentRenew}></Route>
        <Route path="/privacy-policy" component={PrivacyNotice}></Route>
        <Route path="/terms-of-service" component={TermsOfService}></Route>
        <Route path="/data-processing-agreement" component={DataProcessingAgreement} />
        <Route path="/student-data-privacy-agreement" component={StudentDataPrivacy} />
        <Route path="/statement-of-privacy" component={StatementOfPrivacy} />
        <Route path="/student-privacy-pledge" component={StudentPrivacyPledge} />
        <Route path="/end-user-license-agreement" component={EndUserLicenseAgreement} />
        <Route path="/download/:appName" component={Download} />
        <Route path="/jdl" component={JDL} />
        <Route path="/:productType" component={ProductDetail} />
        <Redirect from="*" to="/" />
      </Switch>
    </Appshell>
  );
};
export default Routes;
