import { theme } from '../theme'
import JDigitalLiteracy from "../assets/logos/products/jasperactive-DL-logo.svg";
import JMosOrange from "../assets/logos/products/jmos-orange-logo.svg";
import JMosOrangeOnline from "../assets/logos/products/jmos-online-orange-logo.png";
import JQuickBook from "../assets/logos/products/jasperactive-QB-logo.svg";
import JAdobeCreate from "../assets/logos/products/jasperactive-ac.svg";

export const products = () => {
    let products = [
        {
            logo: JMosOrange,
            title: "",
            description: "Jasperactive for Microsoft Office focuses on the critical thinking and application of learning the Microsoft Office Suite of applications, rather than just learning how to perform functions and accessing features. ",
            theme: theme.palette.secondary.main,
            prodType: "jmos"
        },
        {
            logo: JMosOrangeOnline,
            title: "",
            description: "Specifically formulated for certification readiness, Jasperactive MOS Online is web-based learning for Microsoft Office with tailored exercises for Word, Excel, and PowerPoint Office 365 web applications. ",
            theme: theme.palette.secondary.main,
            prodType: "jmosonline"
        },
        {
            logo: JDigitalLiteracy,
            title: "Jasperactive Digital Literacy",
            description: "Mapped to the IC3 GS6 Global Standard, CCI Learning’s world-class digital literacy courseware comes to life in Jasperactive. ",
            theme: theme.palette.product.digitalLiteracy,
            prodType: "jdl"
        },
        {
            logo: JQuickBook,
            title: "Jasperactive QuickBooks ",
            description: "The perfect addition to any Accounting/Business program, this course of Jasperactive QuickBooks covers all exam objectives for Certiport's QuickBooks Certified User certification.",
            theme: theme.palette.product.quickbooks,
            prodType: "jqb"
        },
        {
            logo: JAdobeCreate,
            title: "Jasperactive Adobe Create",
            description: "Jasperactive Adobe Create is a learning and validation system mapped to the Adobe Certified Professional certification objectives that help learners build the skills and confidence to meet the demands of the design industry.",
            theme: theme.palette.product.adobeCreate,
            prodType: "jac"
        }
    ];
    return products;
}