import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Product from "../Home/Products/Product/Product";
import FAQ from "../FAQs";
import styles from "./styles";
import JDigitalLiteracy from "../../assets/logos/products/jasperactive-DL-logo.svg";
import JMosOrange from "../../assets/logos/products/jmos-orange-logo.svg";
import JMosOrangeOnline from "../../assets/logos/products/jmos-online-orange-logo.png";
import JQuickBook from "../../assets/logos/products/jasperactive-QB-logo.svg";
import MicrosoftLogo from "../../assets/logos/partners/Microsoft-Logo-Partner.svg";
import CertiportHover from "../../assets/logos/partners/certiport-logo-hover.png";
import IC3Logo from "../../assets/logos/partners/IC3-Logo-Partner.svg";
import IntuitLogo from "../../assets/logos/partners/Intuit-QB-Logo-Partner.svg";
import MSWord from "../../assets/logos/mos/ms-word-icon.svg";
import MsExcel from "../../assets/logos/mos/ms-excel-icon.svg";
import MsPPT from "../../assets/logos/mos/ms-ppt-icon.svg";
import MsAccess from "../../assets/logos/mos/ms-access-icon.svg";
import MsOfficeOutlook from "../../assets/logos/mos/ms-outlook-icon.svg";
import JQBCourseLogo from "../../assets/logos/products/jasperactive-QB-course-Logo.svg";
import JACCourseLogo from "../../assets/logos/products/jasperactive-ac.svg";
import DLL1 from "../../assets/logos/digitalliteracy/L1.svg";
import DLL2 from "../../assets/logos/digitalliteracy/L2.svg";
import DLL3 from "../../assets/logos/digitalliteracy/L3.svg";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { theme } from "../../theme";
import DownArrow from "../../assets/other/down-arrow-icon.svg";
import { scroller } from "react-scroll";
import axios from "axios";
import { Loading } from "../ui";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { products } from "../../lib/productsData";
import getFAQs from "../../lib/faqData";
import { default as AdobeInstallatinGuide } from '../adobeInstallationGuide/Main';

const ProductDetail = () => {
  const params = useParams();
  const classes = new styles();
  const [loading, setLoading] = useState(true);
  const { user } = useAuthentication();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const history = useHistory();
  const [productsUrl, setProductsUrl] = useState(null);
  const isMatched = useMediaQuery(theme.breakpoints.down("xs"));
  const [openAdobeInstallationGuide, setOpenAdobeInstallationGuide] = React.useState(false);
  const productType = params.productType?.toLowerCase();

  //Check if user is Subscribed to Specicfic products
  const setSubsribedProduct = useCallback((name) => {
    switch (productType) {
      case "jmos":
        if (name.toLowerCase().includes("mos")) {
          setIsSubscribed(true);
        }
        break;
      case "jmosonline":
        if (name.toLowerCase().includes("office")) {
          setIsSubscribed(true);
        }
        break;
      case "jdl":
        if (name.toLowerCase().includes("digital")) {
          setIsSubscribed(true);
        }
        break;
      case "jqb":
        if (name.toLowerCase().includes("quick")) {
          setIsSubscribed(true);
        }
        break;
      case "jac":
        if (name.toLowerCase().includes("adobe create")) {
          setIsSubscribed(true);
        }
        break;
      default:
        break;
    }
  }, [productType]);

  //Scroll restore to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (location.hash === "#faq") {
      scroller.scrollTo("faq", {
        smooth: true,
      });
    } else if (location.hash === "#14") {
      setShowFAQ(true);
      scroller.scrollTo("14", {
        smooth: true,
      })
    }
  }, [location.hash]);

  useEffect(() => {
    setIsSubscribed(false);
    if (user) {
      Promise.all([axios.get(`/Home/GetProducts/${window.location.hostname}`), axios.get(`/Home/GetProducstUrl/${window.location.hostname}`)]).then((values) => {
        let subscribedProducts = values[0];
        let data = subscribedProducts.data;
        let role = subscribedProducts.data.role;

        if (role === "Instructor") {
          data.teacherProducts.forEach((value) => { setSubsribedProduct(value.productName); });
        } else if (role === "Student") {
          data.studentProducts.forEach((value) => { setSubsribedProduct(value.productName); });
        }

        setProductsUrl(values[1].data);
      }).catch((error) => console.error(error)).finally(() => { setLoading(false); });
    } else {
      axios.get(`/Home/GetProducstUrl/${window.location.hostname}`).then((response) => {
        setProductsUrl(response.data);
      }).catch((error) => console.error(error)).finally(() => { setLoading(false); });
    }
  }, [user, productType, setSubsribedProduct]);

  const getProductUrl = useCallback(
    (applicationName) => {
      if (productsUrl && applicationName) {
        const productUrl = productsUrl.find(
          (product) => product.applicationName === applicationName
        );

        if (productUrl) {
          return productUrl.url;
        } else {
          return "";
        }
      }
    },
    [productsUrl]
  );

  const setProductData = useCallback(() => {
    switch (productType) {
      case "jmos":
        setData({
          productpartner: [MicrosoftLogo, CertiportHover],
          theme: theme.palette.secondary.main,
          logo: JMosOrange,
          goTourl: getProductUrl("Jasperactive MOS"),
          description:
            "Founded on CCI Learning’s world-leading content, Jasperactive for Microsoft Office is the world’s first kinesthetic Microsoft Office learning and validation system mapped to the Microsoft Office Specialist certification objectives. Delivered through Microsoft Office and hosted on Azure, Jasperactive for Microsoft Office focuses on the critical thinking and application of learning Microsoft Office, rather than just learning how to perform functions and accessing features.  ",
          goToButton: "Go to Jasperactive MOS",
          requestDemoButton: "Request Demo",
          requestDemoUrl: "",
          availableCoursesTitle: "Available Courses",
          youtubeVideoSrc: "https://www.youtube.com/embed/aVfnKPlX3KI",
          courses: [
            {
              logo: MSWord,
              topicCovers: "Covers:",
              title: "Microsoft Office Word Associate",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MSWord,
              topicCovers: "Covers:",
              title: "Microsoft Office Word Expert",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MsExcel,
              topicCovers: "Covers:",
              title: "Microsoft Office Excel Associate",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MsExcel,
              topicCovers: "Covers:",
              title: "Microsoft Office Excel Expert",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MsPPT,
              topicCovers: "Covers:",
              title: "Microsoft Office Powerpoint",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MsAccess,
              topicCovers: "Covers:",
              title: "Microsoft Office Access",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
            {
              logo: MsOfficeOutlook,
              topicCovers: "Covers:",
              title: "Microsoft Office Outlook",
              covers: [
                "Microsoft Office 365",
                "Microsoft Office 2019",
                "Microsoft Office 2016",
                "Microsoft Office 2013",
              ],
            },
          ],
        });
        break;

      case "jmosonline":
        setData({
          productpartner: [MicrosoftLogo, CertiportHover],
          theme: theme.palette.secondary.main,
          logo: JMosOrangeOnline,
          description:
            "Jasperactive MOS Online is truly a game-changer. Delivered through Microsoft Office Web apps and hosted on Azure, it can be used by anyone, anytime, and anywhere. Specifically formulated for certification readiness, Jasperactive MOS Online is web-based learning for Microsoft Office with tailored exercises for Word, Excel, and PowerPoint Office 365 web applications.\nAccessible on ChromeOS, MacOS, and More! ",
          goToButton: "Go to Office 365",
          goTourl: getProductUrl("Office 365"),
          requestDemoButton: "Request Demo",
          requestDemoUrl: "https://www.office.com",
          availableCoursesTitle: "Available Courses",
          youtubeVideoSrc: "https://www.youtube.com/embed/tQ0nzPCTgME",
          courses: [
            {
              logo: MSWord,
              topicCovers: "Covers:",
              title: "Microsoft Office Word Associate",
              covers: ["Microsoft 365"],
            },
            {
              logo: MSWord,
              topicCovers: "Covers:",
              title: "Microsoft Office Word Expert",
              covers: ["Microsoft 365"],
            },
            {
              logo: MsExcel,
              topicCovers: "Covers:",
              title: "Microsoft Office Excel Associate",
              covers: ["Microsoft 365"],
            },
            {
              logo: MsExcel,
              topicCovers: "Covers:",
              title: "Microsoft Office Excel Expert",
              covers: ["Microsoft 365"],
            },
            {
              logo: MsPPT,
              topicCovers: "Covers:",
              title: "Microsoft Office Powerpoint",
              covers: ["Microsoft 365"],
            },
          ],
        });
        break;

      case "jdl":
        setData({
          productpartner: [IC3Logo],
          theme: theme.palette.product.digitalLiteracy,
          logo: JDigitalLiteracy,
          description:
            "Digital Literacy is the ability to learn, understand, apply, and formulate ideas using information and communication technology safely and successfully.\nMapped to the IC3 GS6 Global Standard, CCI Learning’s world-class digital literacy courseware comes to life in Jasperactive. ",
          goToButton: "Go to Jasperactive app",
          goTourl: getProductUrl("Jasperactive APP"),
          requestDemoButton: "Try a Free Lesson",
          requestDemoUrl: getProductUrl("Jasperactive APP"),
          availableCoursesTitle: "Available Courses",
          youtubeVideoSrc: "https://www.youtube.com/embed/jSEQaCEaNmc",
          courses: [
            {
              logo: DLL1,
              topicCovers: "In this module, you will:",
              title: "Level 1 Fundamental Concepts and Essential Components",
              covers: [
                "Learn what an operating system is and its importance to a computing device.",
                "Learn what a digital identity is and how to manage it.",
                "Learn how to search for information on the Internet.",
                "Understand what it means to create content for common tasks.",
                "Identify different methods of communicating with others online.",
                "Understand what online collaboration is and how it works.",
                "Understand why it is important to be safe and secure when online.",
              ],
            },
            {
              logo: DLL2,
              topicCovers: "In this module, you will:",
              title: "Level 2 Working Knowledge of Core Skills",
              covers: [
                "Expand technology skills to customize settings for the computing device.",
                "Understand the importance of an online personal and professional identify.",
                "Learn to narrow your search criteria and assess the search results.",
                "Expand your knowledge to create and manage the content.",
                "Recognize ways you can communicate or participate with online apps.",
                "Understand how you can collaborate with others in a digital environment.",
                "Explore the various ways to stay safe and healthy when online.",
              ],
            },
            {
              logo: DLL3,
              topicCovers: "In this module, you will:",
              title: "Level 3 Advanced Understanding of Digital Literacy",
              covers: [
                "Understand technical requirements for hardware or software support.",
                "Recognize and identify what it means to be a good citizen online.",
                "Apply advanced search tools for research purposes.",
                "Understand how to recognize and protect digital content creations.",
                "Expand your knowledge for managing communication online.",
                "Understand how to collaborate with internal teams and clients.",
                "Recognize tools you can apply to protect computing devices.",
              ],
            },
          ],
        });
        break;

      case "jqb":
        setData({
          productpartner: [IntuitLogo],
          theme: theme.palette.product.quickbooks,
          logo: JQuickBook,
          description:
            "The perfect addition to any Accounting/Business program, this course of Jasperactive QuickBooks covers all exam objectives for Certiport's QuickBooks Certified User certification by: " +
            "\n• Employing a variety of methodologies" +
            "\n• Using an exercise-based approach with real-world context " +
            "\n• Guiding the student with a performance level approach " +
            "\n• Allowing learners to assess their own understanding" +
            " \n• Generating quality data for learners and their instructors ",
          goToButton: "Go to Jasperactive app",
          goTourl: getProductUrl("Jasperactive APP"),
          requestDemoButton: "Try a Free Lesson",
          requestDemoUrl: getProductUrl("Jasperactive APP"),
          availableCoursesTitle: "Available Courses",
          youtubeVideoSrc: "https://www.youtube.com/embed/qlHG6Nbm36Q",
          courses: [
            {
              logo: JQBCourseLogo,
              topicCovers: "This module is:",
              title: "QuickBooks",
              covers: [
                "The perfect addition to any Accounting/Business program, this course covers all exam objectives for Certiport's QuickBooks Certified User exam.",
              ],
            },
          ],
        });
        break;
      case "jac":
        setData({
          productpartner: [],
          theme: theme.palette.product.adobeCreate,
          logo: JACCourseLogo,
          description: "Adobe Creative Cloud is a collection of powerful and feature-rich programs. Jasperactive Adobe Create is a learning and validation system mapped to the Adobe Certified Professional certification objectives that help learners build the skills and confidence to meet the demands of the design industry.\nIntroducing the “Show Me” feature: The 'Show Me' feature provides video clips that show the step-by-step process of the lesson to allow learners to see where they went wrong and continue to learn.\nThis learning system is compatible with Windows and Mac devices.",
          goToButton: "GO TO JASPERACTIVE ADOBE CREATE",
          goTourl: getProductUrl("Adobe Create"),
          requestDemoButton: "Request Demo",
          requestDemoUrl: "",
          availableCoursesTitle: "",
          youtubeVideoSrc: "https://www.youtube.com/embed/cW77a4z5XPE",
          courses: []
        });

        break;
      default: {
        setData(null);
      }
    }
  }, [productType, getProductUrl]);

  useEffect(() => {
    if (!loading && productsUrl) {
      setProductData();
    }
  }, [loading, productsUrl, setProductData]);

  const goToCourseSection = () => {
    scroller.scrollTo("courseSection", {
      smooth: true,
    });
  };

  const [showFAQ, setShowFAQ] = useState(false);
  const handleFAQ = () => {
    setShowFAQ(!showFAQ);
  }

  const [data, setData] = useState({
    productpartner: [],
    theme: "",
    logo: "",
    description: "",
    goToButton: "",
    goTourl: "",
    requestDemoButton: "",
    requestDemoUrl: "",
    availableCoursesTitle: "",
    youtubeVideoSrc: "",
    courses: [
      {
        logo: "",
        title: "",
        topicCovers: "",
        covers: [],
      },
    ],
  });

  if (loading) {
    return <Loading />;
  } else if (data === null) {
    return <Typography variant="h1">Error:404, Page not found</Typography>;
  } else
    return (
      <div className={classes.container} id="productdetails">
        <div className={classes.topcontainer}>
          <div className={classes.topLeftcontainer}>
            <div className={classes.logoSection}>
              <img
                src={data.logo}
                alt={data.title}
                style={{
                  marginRight: theme.spacing(2),
                  width: isMatched ? "80%" : "50%",
                }}
              />
              <Typography className={classes.title}>{data.title}</Typography>
            </div>
            {data.description.split("\n").map((value, i) => {
              return (
                <Typography key={i} className={classes.description}>
                  {value}
                </Typography>
              );
            })}

            <div className={classes.buttonSection}>
              <Button
                className={classes.goToButton}
                style={{ backgroundColor: data.theme }}
                onClick={() => {
                  window.open(data.goTourl, "_blank");
                }}
                variant="contained"
              >
                {data.goToButton}
              </Button>
              {isSubscribed && data.logo.indexOf("jasperactive-ac.") > -1 &&
                <Button style={{ color: data.theme, border: "1px solid" + data.theme, }} variant="outlined" onClick={() => { setOpenAdobeInstallationGuide(true); }}>GET DOWNLOAD INSTRUCTIONS</Button>
              }
              {!isSubscribed && data.requestDemoButton ? (
                <Button
                  className={classes.requestDemoButton}
                  style={{
                    color: data.theme,
                    border: "1px solid" + data.theme,
                  }}
                  variant="outlined"
                  onClick={() => {
                    if (
                      productType === "jmos" ||
                      productType === "jmosonline" ||
                      productType === "jac"
                    ) {
                      history.push({
                        pathname: "/pricing",
                        hash: "contactus",
                      });
                    } else {
                      window.open(data.requestDemoUrl, "_blank");
                    }
                  }}
                >
                  {data.requestDemoButton}
                </Button>
              ) : (
                ""
              )}
              {data.courses.length > 0 &&
                <div className={classes.arrow}>
                  <img
                    src={DownArrow}
                    alt="downarrow"
                    onClick={goToCourseSection}
                  />
                </div>
              }
            </div>
          </div>
          <iframe
            width="560"
            height="315"
            title={data.title}
            className={classes.videoSection}
            src={data.youtubeVideoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        {data.productpartner.length > 0 &&
          <div className={classes.productPartnerSection}>
            <Typography className={classes.productPartnerTitle}>
              Partner for this product:
            </Typography>
            {data.productpartner.map((partner, index) => {
              return (
                <img
                  key={index}
                  src={partner}
                  alt="partner"
                  width={
                    !isMatched && index === 1 ? "169px" : isMatched ? "200px" : ""
                  }
                  style={{
                    marginRight: !isMatched ? theme.spacing(4) : "",
                    alignSelf: isMatched ? "center" : "",
                    marginTop: isMatched ? theme.spacing(2) : "",
                  }}
                />
              );
            })}
          </div>
        }
        {data.courses.length > 0 &&
          <div className={classes.courseSection} id="courseSection">
            <Typography className={classes.availableCourse}>
              {data.availableCoursesTitle}
            </Typography>
            <div className={classes.courses}>
              {data.courses.map((value, index) => {
                return (
                  <Card key={index} className={classes.individualCourse}>
                    <img
                      src={value.logo}
                      alt={value.title}
                      style={{
                        paddingTop: theme.spacing(2),
                        width: 100,
                        height: 100,
                      }}
                    />
                    <CardContent>
                      <Typography className={classes.courseTitle}>
                        {value.title}
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography className={classes.coversTitle}>
                        {value.topicCovers}
                      </Typography>
                      <ul className={classes.coversLists}>
                        {value.covers.map((value, index) => {
                          return (
                            <Typography
                              component="li"
                              style={{
                                ...theme.typography.subtitle2,
                                color: theme.palette.grey[400],
                                marginBottom: "10px",
                              }}
                              key={index}
                            >
                              {value}
                            </Typography>
                          );
                        })}
                      </ul>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>
        }
        {productType === "jmosonline" ? (
          <div className={classes.faqSection} id="faq" >
            <Typography className={classes.faqTitle}>
              Frequently Asked Questions
            </Typography>
            {getFAQs().map((faq, index) => {
              return (
                <FAQ
                  id={index}
                  key={index}
                  question={faq.question}
                  number={faq.number}
                  answer={faq.answer}
                  answers={faq.answers}
                  img={faq.img}
                  answerwithlogo={faq.answerswithlogo}
                  handleFAQ={index === 14 ? handleFAQ : ""}
                  showFAQ={showFAQ}
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className={classes.otherProductsSection}>
          <Typography className={classes.otherProductTitle}>
            Other Available Products
          </Typography>
          <div className={classes.otherProductSection}>
            {products()
              .filter((product) => {
                return product.prodType !== productType;
              })
              .map((product, index) => {
                return (
                  <Product
                    key={index}
                    logo={product.logo}
                    title={product.title}
                    description={product.description}
                    theme={product.theme}
                    type={product.prodType}
                  />
                );
              })}
          </div>
        </div>
        <AdobeInstallatinGuide open={openAdobeInstallationGuide} setOpen={setOpenAdobeInstallationGuide} />
      </div>
    );
};

export default ProductDetail;
